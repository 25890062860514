<template>
  <ion-page>
    <template v-if="plan">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ plan.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <div id="container">
          <div class="info">
              {{ plan.description }}
          </div>

          <div class="games">
            <h3>Games</h3>
            <PlanGamesList :planGames="planGames" :plan="plan" :plan-session="planSession" />
          </div>

        </div>
      </ion-content>

      <ion-footer v-if="!isPlanSessionComplete">
        <ion-toolbar class="footer-toolbar">
          <ion-button slot="start" fill="outline" @click="onCompletePlan">
            <ion-icon :ios="checkmarkOutline" :md="checkmarkSharp" />
            Complete
          </ion-button>
          <ion-button v-if="nextIncompleteGame" slot="end" @click="onNextGame">
            Next Game
            <ion-icon :ios="caretForwardOutline" :md="caretForwardSharp" />
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </template>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
} from '@ionic/vue';
import { checkmarkOutline, checkmarkSharp, caretForwardOutline, caretForwardSharp } from 'ionicons/icons';

import PlanGamesList from '@/components/games/PlanGamesList';

export default {
  name: 'PlanSessionDetails',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonFooter,
    IonIcon,

    PlanGamesList,
  },

  setup() {

    return {
      checkmarkOutline,
      checkmarkSharp,
      caretForwardOutline,
      caretForwardSharp,
    };
  },

  created() {
  },

  data() {
    return {

    };
  },

  computed: {
    planSessionId() {
      return this.$route.params.planSessionId;
    },

    planSession() {
      return this.global.planSessions.getById(this.planSessionId);
    },

    isPlanSessionComplete() {
      return !!this.planSession.completedAt;
    },

    plan() {
      return this.global.plans.getById(this.planSession?.planId);
    },

    planGames() {
      return this.plan?.planGames || [];
    },

    nextIncompleteGame() {
      return this.planGames.find((pg) => {
        const gameSession = this.global.gameSessions.getByPlanGameIdAndPlanSessionId(pg.id, this.planSessionId);

        return !gameSession || !gameSession.completedAt;
      });
    },
  },

  methods: {
    onCompletePlan() {
      this.global.planSessions.completePlanSession(this.planSessionId);    
    },

    onNextGame() {
      const gameSession = this.global.gameSessions.createGameSession(this.nextIncompleteGame.gameId, this.nextIncompleteGame.id, this.planSessionId, { ...this.nextIncompleteGame.config });

      this.$router.push({
        name: 'PlanGameSession',
        params: {
          id: gameSession.id,
          planId: this.plan.id,
          planSessionId: this.planSessionId,
        },
      });
    },
  },
}
</script>

<style scoped lang="scss">

  .footer-toolbar {
    padding: 0 1rem;
  }

  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }

  .games {
    h3 { 
      margin: 1rem;
    }
  }
</style>